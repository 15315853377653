
.header_wrapper{
    position: relative;
    height: 175px;
}

.header-background {
    background-color: #262E6E;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.8;
}
.header {
    position: relative;
    left: 0;
    top: 0;
    background-size: 100%;
}

.kiosk_logo {
    max-width: 300px;
    float: left;
    padding-top: 14px;
}

.kiosk_logo img {
    width: 100%;
    margin-top: 10px;
    position: absolute;
}

.header_right_col {
    float: right;
    padding-right: 0px;
    position: relative;
}

.header_slah_titme {
    float: left;
    text-align: right;
    margin-top: 50px;
}

.header_vr_border {
    border-right: #e9eef1 1px solid;
    height: 115px;
    float: left;
    margin: 30px;
}

.header_slah_titme .sl_time {
    color: #ffffff;
    font-size: 24px;
    font-family: 'Roboto-Regular';
}

.header_slah_titme .sl_time span {
    color: #ffffff;
    font-family: 'Roboto-Bold';
    padding-top: 4px;
    display: block;
}

.header_slah_titme .sl_date {
    color: #ffffff;
    font-size: 24px;
    text-align: right;
    font-weight: 500;
}

.date_titme {
    float: right;
    margin-top: 55px;
}

.date_titme .dt_time {
    color: #ffffff;
    font-size: 96px;
    float: left;
    line-height: 72px;
    font-family: 'Roboto-Light';
    margin-left: -6px;
}

.date_titme .dt_date img {
    width: 30px;
}

.date_titme .dt_date {
    color: #ffffff;
    font-size: 48px;
    float: left;
    font-weight: 300;
    margin-left: 19px;
    position: relative;
    top: 5px;
    display: none;
}

.top_logo {
    display: block;
}

.top_logo img {
    width: 100px;
    height: 100px;
    float: left;
    margin-top: 38px;
    border: #fff 3px solid;
    border-radius: 100px;
    background: #fff;
}

.logo_name {
    float: left;
    margin-left: 30px;
    padding-top: 15px;
}

.logo_name h1 {
    color: #fff;
    line-height: 10px;
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 53px;
}

.logo_name p {
    color: #fff;
    font-size: 20px;
    font-family: 'Roboto-Regular';
    margin-top: 15px;
}