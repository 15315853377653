.check-box {
    position: relative;
    width: 100%;
    text-align: left;
    padding-top: 6px;
    padding-bottom: 5px;
    min-height: 20px;
}
.check-box span {
    margin-left: 36px;
    margin-top: 0px;
    font-size: 16px;
    pointer-events: none !important;
    vertical-align: top;
    font-family: "Roboto-Regular";
    color: #222831 !important;
    display: inline-block;
}
.field_card{
    width: 40px;
    border-radius: 3px;
}
.totalCharge {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E4E8EC;
    border-radius: 4px;
    padding: 20px;
    height: 94px;
}

.totalCharge .check-box span{
    color: #818E94 !important;
}


.totalCharge .chargeparagraph {
 font-family: 'Roboto-Regular';
letter-spacing: 0.15px;
color: #818E94;
text-transform: capitalize;
font-weight: normal;
font-size: 16px;
}
.price{
letter-spacing: 0px;
color: #05CE78;
font-size: 24px;
font-family: 'Roboto-Medium';
}
.edit {
color : #818E94;
font-size: 24px;
letter-spacing: 0.23px;
margin-left: 10px;
font-family: 'Roboto-Regular';
text-decoration: underline;
cursor: pointer !important;

}
.MuiCircularProgress-colorPrimary {
    color: #05CE78 !important;
}

.donation_pv{
    color: #818E94;
    font-size: 16px;
    margin-top: 20px;
    font-family: 'Roboto-Regular';

}

.donation_pv span{
    color: #4D4D4D;
    cursor: pointer;
}
